import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import GoogleMapReact from 'google-map-react';
import { FaExternalLinkAlt, FaHotel, FaMapMarkerAlt, FaArrowLeft, FaUtensils, FaShoppingBag, FaHiking, FaWineBottle, FaStar } from 'react-icons/fa';
import { IoMdWine, IoIosCafe, IoMdFitness } from 'react-icons/io';
import ReactMarkdown from 'react-markdown';

import { calculateMercatorFromLatLng, calculateZoomFromMercator } from 'utils/mapUtils';
import CompassImage from '../../assets/compass.jpg';
import IslandImage from '../../assets/island.jpg';
import FrameL , { Img } from 'component/FrameL';
import HotelMapIcon from '../../assets/hotel-map-icon.png';

import './style.css';

const urlPattern = /^((http|https):\/\/)/;

const hotelLatLng = { lat: 47.22022899425376, lng: -114.99332735616629 };
const hotelMercator = calculateMercatorFromLatLng(hotelLatLng);

const icons = {
    restaurant: FaUtensils,
    shopping: FaShoppingBag,
    outdoors: FaHiking,
    bar: IoMdWine,
    wine: FaWineBottle,
    cafe: IoIosCafe,
    entertainment: FaStar,
    health: IoMdFitness,
}

export default ({ cmsData = {} }) => {
    const [isCategorySelected, setIsCategorySelected] = useState(false);
    const [isDestSelected, setIsDestSelected] = useState(false);
    const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(-1);
    const [selectedDestIndex, setSelectedDestIndex] = useState(-1);

    const { Categories = [], Introduction } = cmsData;

    const selectedCategory = Categories[selectedCategoryIndex] || {};
    // const isBeaches = isCategorySelected && selectedCategory.CategoryName === 'Beaches';
    // const isConcierges = isCategorySelected && selectedCategory.CategoryName === 'Concierges';
    const selectedDest = selectedCategory?.CategoryItems?.[selectedDestIndex] || {};
    let mapCenter = hotelLatLng;
    let zoom = 16;

    const poiRef = useRef(null);
    const location = useLocation();

    useEffect(() => {
        const [cIndex, dIndex] = location.hash.replace("#", "").split("-");
        if (cIndex && cIndex < Categories.length && dIndex && dIndex < Categories[cIndex].CategoryItems?.length) {
            setIsCategorySelected(true);
            setSelectedCategoryIndex(cIndex);
            setIsDestSelected(true);
            setSelectedDestIndex(dIndex);

            const t = setTimeout(() => {
                poiRef?.current && poiRef.current.scrollIntoView();
            }, 0);

            return () => {
                clearTimeout(t);
            }
        }
    }, [location.hash, poiRef.current]);

    if (isDestSelected && selectedDest?.Latitude && selectedDest?.Longitude) {
        const destinationLatLng = { lat: parseFloat(selectedDest.Latitude), lng: parseFloat(selectedDest.Longitude) }
        mapCenter = destinationLatLng;
        const destinationMercator = calculateMercatorFromLatLng(destinationLatLng);
        zoom = calculateZoomFromMercator(hotelMercator, destinationMercator, poiRef.current.offsetWidth, 600);
    }

    let sideContent = <>
        <div className="w-1/2 mx-auto sm:w-full">
            <FrameL ratio="1:1" imgClasses="rounded-full" src={CompassImage} isExternal={false} />
        </div>
        <h3 className="h1 text-center">Explore Our Neighborhood</h3>
    </>;

    let mainContent;

    if (isCategorySelected && isDestSelected) {
        let { Name, Description = '', Phone, Website, Photo = [] } = selectedDest;

        if (!urlPattern.test(Website)) {
            Website = `//${Website}`;
        }

        mainContent = <div className="py-4 leading-normal">
            <ReactMarkdown>{Description}</ReactMarkdown>
            <p><strong>Phone: </strong>{Phone}</p>
            <a className="underline" href={Website} target="_blank" rel="noopener noreferrer">Visit Website <FaExternalLinkAlt className=" inline ml-2"></FaExternalLinkAlt> </a>
        </div>;
        sideContent = <>
            <FrameL ratio="1:1" key={Name} imgClasses="rounded-full" Image={Photo} />
            <h3 className="h1 text-center">
                <FaArrowLeft onClick={() => { setIsDestSelected(false) }} className="cursor-pointer inline mr-4" />
                {Name}
            </h3>
        </>;
    } else if (isCategorySelected) {
        const { CategoryName, CategoryItems = [] } = selectedCategory;
        sideContent = <>
            <FrameL ratio="1:1" key={CategoryName} imgClasses="rounded-full" src={CompassImage} isExternal={false} />
            <h3 className="h1 text-center">
                {CategoryName}
            </h3>
        </>;

        mainContent = <div className='p-4 poi__category-items' style={{ '--space': 'var(--s-4)' }} >
            {CategoryItems.map(({ Name }, i) => {
                return <p key={i} className="cursor-pointer font-bold leading-loose" onClick={() => {
                    setIsDestSelected(true);
                    setSelectedDestIndex(i);
                }}>
                    {Name}
                </p>
            })}
        </div>
    }

    return <main className='xl:container mx-auto stack-l mt-24' style={{ '--space': 'var(--s5)' }}>
          <section ref={poiRef} style={{ '--space': '0' }}>
            {!isCategorySelected ?
                <div className='relative break-out-banner'>
                    <div className='text-center hide-on-md max-w-sm text-white poi__map-instruction'>
                        <h3 className='uppercase'>Interactive Maps</h3>
                        {/* <p>Click dots for our insider's review of each beach: the beach experience, what people say and amenities. Or, scroll down to read more.</p> */}
                    </div>
                    <div className='poi__map--height w-full flex'>
                    <GoogleMapReact bootstrapURLKeys={{ key: process.env.REACT_APP_GMAP_API_KEY }}
                        defaultCenter={mapCenter}
                        center={mapCenter}
                        defaultZoom={zoom}
                        zoom={zoom}>
                        <img  className="text-blue-600 text-4xl poi__marker--translate" src={HotelMapIcon}
                            lat={hotelLatLng.lat}
                            lng={hotelLatLng.lng}
                            text="Hotel"
                        ></img>                            
                        {/* <FaHotel className="text-blue-600 text-4xl poi__marker--translate"
                            lat={hotelLatLng.lat}
                            lng={hotelLatLng.lng}
                            text="Hotel"/> */}
                    </GoogleMapReact>
                    </div>
                    {isCategorySelected && selectedCategory.CategoryItems.map((dest, i) => {
                        return <button
                            className={`absolute poi__image-marker bg-red-700 ${isDestSelected && selectedDestIndex === i ? 'active' : ''}`}
                            key={i}
                            style={{
                                top: `${dest.Latitude}%`,
                                left: `${dest.Longitude}%`
                            }}
                            onClick={() => {
                                setIsDestSelected(true);
                                setSelectedDestIndex(i);
                            }}
                        />
                    })}
                </div> :
                <div className="break-out-banner relative poi__map--height w-full flex">
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: process.env.REACT_APP_GMAP_API_KEY }}
                        defaultCenter={mapCenter}
                        center={mapCenter}
                        defaultZoom={zoom}
                        zoom={zoom}
                    >
                        <img  className="text-blue-600 text-4xl poi__marker--translate" src={HotelMapIcon}
                            lat={hotelLatLng.lat}
                            lng={hotelLatLng.lng}
                            text="Hotel"
                        ></img>                            


                        {!isCategorySelected && !isDestSelected && Categories.map(({ CategoryItems = [], Icon }, cIndex) => {
                            return CategoryItems.map((dest, dIndex) => {
                                const Marker = icons[Icon] || FaMapMarkerAlt;
                                return <Marker
                                    key={`cat${cIndex}-dest${dIndex}`}
                                    className="text-blue-800 text-xl poi__marker--translate"
                                    lat={dest.Latitude}
                                    lng={dest.Longitude}
                                    onClick={() => {
                                        setIsCategorySelected(true);
                                        setIsDestSelected(true);
                                        setSelectedCategoryIndex(cIndex);
                                        setSelectedDestIndex(dIndex);
                                    }}
                                />
                            });
                        })}

                        {isCategorySelected && !isDestSelected && selectedCategory.CategoryItems.map((dest, i) => {
                            const Marker = icons[selectedCategory.Icon] || FaMapMarkerAlt;
                            return <Marker
                                className="text-blue-800 text-xl poi__marker--translate"
                                key={i}
                                lat={dest.Latitude}
                                lng={dest.Longitude}
                                onClick={() => {
                                    setIsDestSelected(true);
                                    setSelectedDestIndex(i);
                                }}
                            />
                        })}

                        {(() => {
                            if (isDestSelected) {
                                const Marker = icons[selectedCategory.Icon] || FaMapMarkerAlt;
                                return <Marker lat={mapCenter.lat} lng={mapCenter.lng} className="text-blue-800 text-xl poi__marker--translate" />
                            }
                        })()}
                    </GoogleMapReact>
                </div >
            }

            {!isCategorySelected ? null : <div className='sidebar-l mx-4 md:mx-10 md:px-10 border-r border-l border-b border-gray-500' style={{ "--sidewidth": "200px", "--space": "var(--s2)" }}>
                <div className='stack-l -mt-12 sm:-mt-20' style={{ "--space": "var(--s2)" }}>
                    {sideContent}
                </div>
                {/* <div className='sidebar-l' style={{ "--sidewidth": "200px", "--space": "var(--s2)", "flexDirection": "row-reverse" }}>
                    <div className='stack-l p-4 bg-blue-100' style={{ '--space': 'var(--s-4)' }}>
                        {Categories.map(({ CategoryName }, i) => {
                            return <p className="cursor-pointer font-bold leading-loose" key={i} onClick={() => {
                                setIsCategorySelected(true);
                                setIsDestSelected(false);
                                setSelectedCategoryIndex(i);
                            }}>{CategoryName}</p>
                        })}
                    </div> */}
                <div className='py-8'>
                    {mainContent}
                </div>
                {/* </div> */}
            </div>}
        </section>

        <section>
            <div id="page-content" className='stack-l px-4 max-w-screen-md mx-auto' style={{ "--space": "var(--s2)" }}>
                <h3 className='text-center h2 uppercase'>Insider's Guide</h3>
                <div>
                    <ul className='cluster-l' style={{ "--space": "var(--s-3)", "--justify": "center" }}>
                        {Categories.map(({ CategoryName }, i) => {
                            return <li
                                className='px-2'
                                // className={`px-2 border-r border-dark-gray`}
                                key={i}>
                                <button className={`focus:outline-none uppercase font-bold leading-loose ${selectedCategoryIndex === i ? 'border-b border-dark-gray' : ''}`}
                                    onClick={() => {
                                        setIsCategorySelected(true);
                                        setIsDestSelected(false);
                                        setSelectedCategoryIndex(i);
                                    }}>{CategoryName}</button>
                            </li>
                        })}
                    </ul>
                </div>
                {isCategorySelected ? <div>
                    <h3 className="text-center h2 uppercase">{selectedCategory.CategoryName}</h3>
                    <ReactMarkdown className='mt-4 text-lg text-justify'>{selectedCategory.Description}</ReactMarkdown>
                </div> :
                    <ReactMarkdown className='text-lg text-justify'>{Introduction}</ReactMarkdown>}
            </div>
        </section>

        <section className='grid-l px-4' style={{ "--space": "var(--s3)", "--min": "300px" }}>
            {isCategorySelected && selectedCategory.CategoryItems.map((dest, i) => {
                let { Name, Description = '', Phone, Website, Photo } = dest;

                return <article
                    className="stack-l"
                    key={i}
                // onClick={() => {
                //     setIsDestSelected(true);
                //     setSelectedDestIndex(i);
                // }}
                >
                    <FrameL ratio='1:1' imgClasses="rounded-full" Image={Photo} />
                    <h3 className='text-center'>{Name}</h3>
                    <ReactMarkdown className='leading-normal text-justify'>{Description}</ReactMarkdown>
                    <div>
                        <p><strong>Phone: </strong>{Phone}</p>
                        <a className="underline" href={Website} target="_blank" rel="noopener noreferrer">Visit Website</a>
                    </div>
                </article>
            })}
        </section>
    </main>;
}
